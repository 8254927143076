import * as React from 'react';
import { AbilityType, toAbilityName } from '../../api/DnD/abilities';
import { DiceType } from '../../api/DnD/dices';
import { useCalculatedRollResults } from '../../redux/dices/selectors';
import { CalculatedRollResults, RollResult } from '../../redux/state';

import styles from './RollResultsNotification.module.css';

type Props = {
  style?: React.CSSProperties | undefined;
  className?: string;
};

export const RollResultsNotification: React.FC<Props> = ({ style, className }) => {
  const calculatedRollResults = useCalculatedRollResults();

  if (!calculatedRollResults) {
    return null;
  }

  const { withAbility, withRollType, withResultModifier, finalResult } = calculatedRollResults;
  let title = withAbility ? `${toAbilityName(withAbility.type)} check` : 'Roll result';

  if (withRollType === 'advantage') {
    title += ' with Advantage';
  } else if (withRollType === 'disadvantage') {
    title += ' with Disadvantage';
  }

  return (
    <div className={className} style={style}>
      <div className={styles.container}>
        <div>{title}</div>
        <div className={styles.content}>
          {/* Roll Results */}
          {withRollType === 'normal' ? (
            <GroupedRollResults rollResults={calculatedRollResults} />
          ) : (
            <RollResultsWithAdvantageOrDisadvantage rollResults={calculatedRollResults} />
          )}
          {/* Additional Modifiers */}
          {getAdditionalModifiers(withAbility, withResultModifier)}
          <div className={styles.plus}>=</div>
          <div className={styles.finalScore}>{finalResult}</div>
        </div>
      </div>
    </div>
  );
};

type RollCalculationsProps = {
  rollResults: CalculatedRollResults;
};

const RollResultsWithAdvantageOrDisadvantage: React.FC<RollCalculationsProps> = ({ rollResults }): JSX.Element => {
  const { results, withRollType } = rollResults;
  let ignoreItemIndex = -1;

  if (withRollType === 'advantage') {
    ignoreItemIndex = 0;
  } else if (withRollType === 'disadvantage') {
    ignoreItemIndex = 1;
  }

  return (
    <>
      {/* Roll Results */}
      {results.map(({ value, diceType }, index, arr) => (
        <React.Fragment key={index}>
          <div className={styles.rolled}>
            <div className={ignoreItemIndex === index ? `${styles.score} ${styles.strikethrough}` : styles.score}>
              {value}
            </div>
            <div className={styles.diceSource}>{diceType}</div>
          </div>
          {index < arr.count() - 1 && <div className={styles.plus}>vs</div>}
        </React.Fragment>
      ))}
    </>
  );
};

const GroupedRollResults: React.FC<RollCalculationsProps> = ({ rollResults }): JSX.Element => {
  const { results } = rollResults;

  const rollGroups = groupDicesByType(results);

  return (
    <>
      {Array.from(rollGroups, ([diceType, value], index) => (
        <React.Fragment key={index}>
          <div className={styles.rolled}>
            <div className={styles.score}>{value.summ.join('+')}</div>
            <div className={styles.diceSource}>{value.count > 1 ? `${value.count}${diceType}` : diceType}</div>
          </div>
          {index < rollGroups.size - 1 && <div className={styles.plus}>+</div>}
        </React.Fragment>
      ))}
    </>
  );
};

const getAdditionalModifiers = (
  withAbility:
    | {
        type: AbilityType;
        modifier: number;
      }
    | undefined,
  withResultModifier: number | undefined,
) => (
  <>
    {/* Ability Check */}
    {withAbility && (
      <>
        <div className={styles.plus}>+</div>
        <div className={`${styles.rolled} ${styles.mod}`}>
          <div className={styles.score}>{withAbility.modifier}</div>
          <div className={styles.scoreSource}>{withAbility.type}</div>
        </div>
      </>
    )}
    {/* Additional Result Modifier */}
    {!!withResultModifier && withResultModifier !== 0 && (
      <>
        <div className={styles.plus}>+</div>
        <div className={`${styles.rolled} ${styles.mod}`}>
          <div className={styles.score}>{withResultModifier}</div>
          <div className={styles.scoreSource}>RM</div>
        </div>
      </>
    )}
  </>
);

const groupDicesByType = (results: Immutable.List<RollResult>) => {
  const rollGroups = results.reduce((prev, item) => {
    let group = prev.get(item.diceType);

    if (group) {
      group.count += 1;
      group.summ.push(item.value);
    } else {
      group = { count: 1, summ: [item.value] };
    }

    prev.set(item.diceType, group);

    return prev;
  }, new Map<DiceType, { count: number; summ: number[] }>());

  return rollGroups;
};
