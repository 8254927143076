import { PublicApi, Triplet } from '@react-three/cannon';
import React, { useEffect, useRef } from 'react';
import { Clock } from 'three';
import { DiceType } from '../../api/DnD/dices';
import { useDicesToRoll } from '../../redux/dices/selectors';
import { RollResult, RollSource } from '../../redux/state';
import { easeInExpo, easeLinear } from '../../utils/easings';
import { DiceInstances } from './DiceInstances';
import { DragInfo } from './diceTypes';
import { playSound, useDiceSounds } from './sounds';
import { randomizeVelocity } from './utils';

const DICE_TYPES = [DiceType.d20, DiceType.d12, DiceType.d10, DiceType.d8, DiceType.d6, DiceType.d4];
const ANIMATION_DURATION_MS = 200;
const EMPTY_VELOCITY: [number, number] = [0, 0];

type Props = {
  dragInfo: DragInfo | undefined;
  onDicesGrabbed: () => void;
  onDicesRolled: () => void;
  onRollCompleted: (rollResults: RollResult[], source: RollSource) => void;
};

export const Dices: React.FC<Props> = ({ dragInfo, onDicesGrabbed, onDicesRolled, onRollCompleted }): JSX.Element => {
  const dicesToRoll = useDicesToRoll();
  const diceSounds = useDiceSounds();

  //TODO: useVariable https://github.com/uidotdev/usehooks/issues/44
  const largestDiceRef = useRef<DiceType>(DiceType.d4);
  /**
   * We are tracking each dice individually. To do that we use combination of diceType + instanceIndex as a key.
   * Value contains a rolledResult for this particular dice.
   * If dice starts moving again, it can heppen when other dice hits the current dice.
   * In this case we need to remove the dice from lastRollResultsRef and wait until it stops with the new result.
   */
  const lastRollResultsRef = useRef<Map<string, RollResult>>(new Map()); // key: diceType + instanceIndex, value: rolledValue
  const lastRollSourceRef = useRef<RollSource>('tap');

  const dragStartTime = useRef<number | undefined>(undefined);

  useEffect(() => {
    for (const diceType of DICE_TYPES) {
      if (dicesToRoll.has(diceType)) {
        largestDiceRef.current = diceType;
        break;
      }
    }
  }, [dicesToRoll]);

  const onFrame = (
    diceType: DiceType,
    instanceIndex: number,
    clock: Clock,
    api: PublicApi,
    pos: Triplet | undefined,
  ) => {
    if (dragInfo?.first) {
      onDicesGrabbed();
    }

    //TODO: refactor this code, maybe split it in multiple functions
    if (dragInfo?.eventType === 'drag' && pos && dragInfo.position3D && dragInfo.startPosition3D) {
      api.sleep();

      if (!dragStartTime.current) {
        // set start time when drag is staring
        dragStartTime.current = clock.getElapsedTime();
      }

      const elipsedTimeSec = clock.getElapsedTime() - dragStartTime.current || 0;
      const elipsedTimeMs = elipsedTimeSec * 1000;
      const start = pos;
      const end = dragInfo.position3D;

      const x = getAnimValue(start[0], end[0], elipsedTimeMs, ANIMATION_DURATION_MS, easeLinear);
      const y = getAnimValue(start[1], end[1], elipsedTimeMs, ANIMATION_DURATION_MS, easeInExpo);
      const z = getAnimValue(start[2], end[2], elipsedTimeMs, ANIMATION_DURATION_MS, easeLinear);

      api.position.set(x, y, z);

      //NOTE: instanceIndex > 0 needed to scale down largest dice if there are 2+ dices of that type
      if (diceType !== largestDiceRef.current || instanceIndex > 0) {
        const scale = getAnimValue(1, 0.2, elipsedTimeMs, ANIMATION_DURATION_MS, easeInExpo);
        api.scaleOverride([scale, scale, scale]);
      }
    } else if (dragInfo && dragInfo.eventType !== 'drag') {
      api.wakeUp();

      //reset start time, when drag is complete
      dragStartTime.current = undefined;

      //reset scale
      api.scaleOverride([1, 1, 1]);

      if (dragInfo.position3D && dragInfo.eventType === 'tap') {
        // if drag ended before easing finished, we need to ajust position. Otherwise tap would look weird.
        api.position.set(dragInfo.position3D[0], dragInfo.position3D[1], dragInfo.position3D[2]);
      }

      if (dragInfo.position3D && pos && dragInfo.eventType === 'swipe') {
        // if drag ended before easing finished, we need to ajust position.
        api.position.set(pos[0], dragInfo.position3D[1], pos[2]);
      }

      const { velocity, angularVelocity } = randomizeVelocity(dragInfo.velocity || EMPTY_VELOCITY);
      api.velocity.set(...velocity);
      angularVelocity && api.angularVelocity.set(...angularVelocity);

      lastRollSourceRef.current = dragInfo.eventType;

      // Play sound when rolled multiple dices
      if (diceSounds?.startRollSound && dicesToRoll.count() > 1) {
        playSound(diceSounds?.startRollSound, 1, false);
      }

      onDicesRolled();
    }

    // user dragged dices and starts a new roll
    if (dragInfo?.eventType === 'drag') {
      // clearing the previous results
      lastRollResultsRef.current.clear();
    }
  };

  const onStarted = (diceType: DiceType, instanceIndex: number) => {
    //NOTE: deleting roll result since dice started moving again
    lastRollResultsRef.current.delete(toKey(diceType, instanceIndex));
  };

  const onStopped = (diceType: DiceType, instanceIndex: number, rolledValue: number) => {
    // we are not dragging anymore
    if (!dragInfo) {
      lastRollResultsRef.current.set(toKey(diceType, instanceIndex), { diceType, value: rolledValue });

      const stoppedDicesCount = lastRollResultsRef.current.size;
      const dicesToRollCount = dicesToRoll.reduce((prev, dicesCount) => prev + dicesCount, 0);

      // all roll results are received
      if (dicesToRollCount === stoppedDicesCount) {
        onRollCompleted([...lastRollResultsRef.current.values()], lastRollSourceRef.current);
      }
    }
  };

  return (
    <>
      {dicesToRoll.entrySeq().map(([diceType, count]) => (
        <DiceInstances
          key={diceType}
          diceType={diceType}
          count={count}
          diceSounds={diceSounds}
          onFrame={onFrame}
          onStarted={onStarted}
          onStopped={onStopped}
        />
      ))}
    </>
  );
};

const getAnimValue = (
  begin: number,
  end: number,
  expired: number,
  duration: number,
  easing: (x: number) => number,
): number => {
  const animProgress = Math.min(1, expired / duration);
  return begin + (end - begin) * easing(animProgress);
};

const toKey = (diceType: DiceType, instanceIndex: number) => `${diceType}-${instanceIndex}`;
